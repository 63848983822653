:root [cds-theme] {
    --color-channel-switcher-bg: #a240b7;
    --color-channel-switcher-hover-bg: #a240b7;
    --color-left-nav-text: #FFF;
    --color-left-nav-text-hover: #fff;
    --color-primary-700: #a240b7;
    --color-primary-800: #A24090;
    --color-primary-500: #A24090;
    --color-primary-600: #A24090;
    --color-primary-200: #a240b7
    --color-primary-900: #FFF;
    --color-weight-900: #333;
    --color-icon-button: #A24090;
    --clr-toggle-bg-color-on: #A24090;
    --clr-link-visited-color: #A24090;

    .button-small.active[_ngcontent-ng-c2810463730] {
        color: #a240b7;
    }
    .DeliveryFailed .wrapper {
        border-color: var(--color-chip-error-border) !important;
        color: var(--color-chip-error-text) !important;
        background-color: var(--color-chip-error-bg) !important; 
    }
    .Completed .wrapper, .RTO .wrapper{
        border-color: var(--color-chip-success-border) !important;
        color: var(--color-chip-success-text) !important;
        background-color: var(--color-chip-success-bg) !important;
    }
    .Closed .wrapper {
        border-color: #ccc !important;
        color: #333 !important;
        background-color: #cccc !important;
    }
    .active-channel clr-icon {
        color: #fff !important;
    }
}

